import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeSliderSwitch,
    placeRightText,
    placeLogo,
    placeShuffle,
    placeTest,
    placeMiddleText,
    placeBoldText,
    placeEraseLeft
} from '../Utils';
const Boxes = {
    box1: function () {
      JXG.Options.point.showInfoBox=false;
      JXG.Options.point.highlight=false;
      JXG.Options.text.highlight=false;
      JXG.Options.text.fixed=true;
      JXG.Options.curve.highlight=false;
      JXG.Options.circle.highlight=false;
      JXG.Options.image.highlight=false;
      JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
      var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-9, 9, 9, -9],
          keepaspectratio: true, axis:false, ticks:{visible:false},
          grid:true, showCopyright:false, showNavigation:false,
          pan:{enabled:false}, zoom:{enabled:false}});
      brd1.suspendUpdate();
      //Make Responsive
      makeResponsive(brd1);
      //Place Title
      placeTitle(brd1, 'Understanding Sets', '(Union & Intersection of Sets)');
      //
      placeLogo(brd1);
      //
      var erase = placeEraseLeft(brd1);
      //
      erase.on('down', function(){
          clearInputFields(brd1);
          yesText.setAttribute({visible:false});
          noText.setAttribute({visible:false});
          chk.setAttribute({visible:false});
          crs.setAttribute({visible:false});});
      var go =placeTest(brd1);
    //  placeImage(brd1, '/assets/shuffle.svg', 5.5, 5, 2, 0);
      //Variables
      var radius = 3.0;
      var ax = -2;
      var ay = -1.5;
      var bx = 2;
      var by = -1.5;
      var cx = 0;
      var cy = 1.5;

      var wpol = 5;
      var hpol = 5;
      var color1 = 'OldLace';
      var color2 = '#FF3B3B ';
      var color3 = '#FF3B3B ';
      var color4 = 'OldLace ';
      var colorx = 'red';
      var colory = 'green';
      var colorz = 'blue';
      var setA =[], setB=[];
      var setAA=[], setBB=[];
      placeMiddleText(brd1, -6.5, 5.5, 'Set A');
      placeMiddleText(brd1, -6.5, 3.5, 'Set B');
      placeImage(brd1, '/assets/SetA.svg', -7.4, 4.6, 1.8, 0);
      placeImage(brd1, '/assets/SetB.svg', -7.4, 2.6, 1.8, 0);
      //placeRightText(brd1, -6.5, 1.5, 'A ⋂ B :');
      placeMiddleText(brd1, -5, 5.5, '= {');
      placeMiddleText(brd1, -4.+6, 5.5, '}');
      placeMiddleText(brd1, -5, 3.5, '= {');
      placeMiddleText(brd1, -4.+5, 3.5, '}');
      ///////////////////////////////////////////////////////////////////////////////////
      var InpA= []; var InpB=[]; var InpAOB=[];
      InpA[0] = brd1.create('input', [ax-0.5, 0.5, '', ''], {fixed: true,
        cssStyle: 'fontFamily:Oswald; width:5%;'});

      InpA[1] = brd1.create('input', [ax-2, -0.5, '', ''], {fixed: true,
          cssStyle: 'fontFamily:Oswald; width:5%; background-color:#008CBA;border:0px solid black;border-radius:3.5px;'});
      //
      InpA[2] = brd1.create('input', [ax-0.5, -1.5, '', ''], {fixed: true,
            cssStyle: 'fontFamily:Oswald; width:5%; background-color:#008CBA;border:0px solid black;border-radius:3.5px;'});
      InpA[3] = brd1.create('input', [ax-2, -2.5, '', ''], {fixed: true,
                  cssStyle: 'fontFamily:Oswald; width:5%; background-color:#008CBA;border:0px solid black;border-radius:3.5px;'});
      ////////////////////////////////////////////////////////////////////////////
      InpB[0] = brd1.create('input', [bx+1, -0.25, '', ''], {fixed: true,
          cssStyle: 'fontFamily:Oswald; width:5%; background-color:#FF4C26;border:0px solid black;border-radius:3.5px;'});
      //
      InpB[1] = brd1.create('input', [bx-0.5, -1.5, '', ''], {fixed: true,
            cssStyle: 'fontFamily:Oswald; width:5%;background-color:#FF4C26;border:0px solid black;border-radius:3.5px;'});
      InpB[2] =brd1.create('input', [bx+1, -2.5, '', ''], {fixed: true,
                  cssStyle: 'fontFamily:Oswald; width:5%; background-color:#FF4C26;border:0px solid black;border-radius:3.5px;'});
      /////////////////////////////////////////A common B /////////////////////////////////////////
      InpAOB[0] = brd1.create('input', [(ax+bx)*0.5-0.5, -0.5, '', ''], {fixed: true, maxlength:2,
          cssStyle: 'fontFamily:Oswald; width:5%;background-color:#FF4C26;border:0px solid black;border-radius:3.5px;'});
      InpAOB[1] = brd1.create('input', [(ax+bx)*0.5-0.5, -2., '', ''], {fixed: true, maxlength:2,
              cssStyle: 'fontFamily:Oswald; width:5%;background-color:#FF4C26;border:0px solid black;border-radius:3.5px;'});
      //var TestAOB = InpAOB.sort();
      var setAOB = [];
      var setUniqA =[];
      var setUniqB =[];

      //setAOB[0]=()=>InpAOB[0].Value();
      //setAOB[1]=()=>InpAOB[1].Value();
      /*var fill = function()
      {
      setAOB[0]=InpAOB[0].Value();
      setAOB[1]=InpAOB[1].Value();
    }*/
  //  var go = placeImage(brd1, '/assets/go.svg', 6, -2, 1, 0);
    //go.on('down', function(){set=setAOB.sort()});
      //placeText(brd1, 0, 2.5, TestAOB[0]);
      //placeText(brd1, 1, 2.5, TestAOB[1]);
      //placeInput(brd1, ax-2, -2);
      //placeInput(brd1, ax-2, -4);
      var runA = function()
      {
      for (let i=0; i<6; i++)
      {
        setA[i] = 2*(i+1);
        setAA[i]=placeMiddleText(brd1, -4.+1*i, 5.5, 2*(i+1));
        setAA[i].setAttribute({fixed:false});
      }
      };
      var runB = function()
      {
      for (let j=0; j<5; j++)
      {
        setB[j] = 3*(j+1);
        setBB[j]=placeMiddleText(brd1, -4.+1*j, 3.5, (0*Math.round(Math.random())+3)*(j+1));
        setBB[j].setAttribute({fixed:false});
      }
      };
    runA();
    runB();
    var uniqA = FUE(setA, setB);
    var uniqB = FUE(setB, setA);
    var comAB = FCE(setA, setB);
    //var val = brd1.create('text',[0, 2.5, comAB[0]]);
    var chk =placeImage(brd1, '/assets/check.svg', 4, -8, 1, 0);
    var yesText = placeLeftText(brd1, 5.5, -7.5, 'You got it!');
    var noText = placeLeftText(brd1, 5.5, -7.5, 'Try again!');
    yesText.setAttribute({visible:false});
    noText.setAttribute({visible:false});
    var crs =placeImage(brd1, '/assets/cross.svg', 4., -8., 1, 0);
    chk.setAttribute({visible:false});
    crs.setAttribute({visible:false});
    go.on('down', function()
    { setUniqA=[];
      for (let k=0; k<4; k++)
      {
        setUniqA.push(1*InpA[k].Value());
      }
      setUniqB=[];
      for (let k=0; k<3; k++)
      {
        setUniqB.push(1*InpB[k].Value());
      }
      setAOB=[];
      for (let k=0; k<2; k++)
      {
      setAOB.push(1*InpAOB[k].Value());
      }
      //if(comAB.includes(setAOB[0])&&comAB.includes(setAOB[1])&&setAOB[0]!=setAOB[1])
      if(setAOB.every(item => comAB.includes(item)) &&
      comAB.every(item => setAOB.includes(item)) &&
      uniqA.every(item => setUniqA.includes(item)) &&
      setUniqA.every(item => uniqA.includes(item)) &&
      uniqB.every(item => setUniqB.includes(item)) &&
      setUniqB.every(item => uniqB.includes(item)))
      {
      chk.setAttribute({visible:true});
      yesText.setAttribute({visible:true});
      noText.setAttribute({visible:false});
      crs.setAttribute({visible:false});
      }
      else
      {
      crs.setAttribute({visible:true});
      chk.setAttribute({visible:false});
      yesText.setAttribute({visible:false});
      noText.setAttribute({visible:true});
      }
    });

    var writeArray = function(set, x, y)
    {
      for (let k=0; k<set.length; k++)
      {
      placeMiddleText(brd1, x, y+k+0.5-set.length/2, set[k]);
      }
    }
    placeLine(brd1, [ax, -3], [ax, -5], 2, 'red');
    placeLine(brd1, [bx, -3], [bx, -5], 2, 'red');
    placeLine(brd1, [(bx+ax)*0.5, -3], [(ax+bx)*0.5, -5], 2, 'blue');
    ///////////////////////////
    function FCE(array1, array2) {
    let array=[];
	// Loop for array1
	    for(let i = 0; i < array1.length; i++) {
		// Loop for array2
		  for(let j = 0; j < array2.length; j++) {
			      if(array1[i] == array2[j]) {
              array.push(array1[i]);
			         }
             }
           }
    	return array;
    }
    function FUE(array1, array2)
    {
      let array=[];
  // Loop for array1
      for(let i = 0; i < array1.length; i++)
      {
    // Loop for array2
            if(array2.includes(array1[i]) ==false)
            {
              array.push(array1[i]);
            }
      }
      return array;
    }
    function isEqual(array1, array2)
    {
      if(JSON.stringify(array1)==JSON.stringify(array2))
      {
        return "True"
      }
      else
      {
        return "False" ;
      }
    }
///////////////////////////
//var universe =brd1.create('circle', [[0,0], [7, 0]], {fillOpacity:1, dash:1, strokeColor: 'black', strokeWidth: 1, fillColor: color4, layer: 2, highlightStrokeColor: 'black', highlightfillColor: color2})
// Create two arcs (or circles)
var p1 = brd1.create('point', [ax, ay], {face:'o' , name:'p1', strokeColor: 'black', fillColor: '#5B43FF',  size:3, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var p2 = brd1.create('point', [ax + radius, ay], {face:'o' , name:'p2', strokeColor: 'black', fillColor: 'red',  size:3, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var a1 = brd1.create('circle', [p1,p2], {fillOpacity:1, dash:1, strokeColor: 'black', strokeWidth: 1, fillColor: color4, layer: 2, highlightStrokeColor: 'black', highlightfillColor: color2});

var p3 = brd1.create('point', [bx, by], {face:'o' , name:'p3', strokeColor: 'black', fillColor: '#5B43FF',  size:3, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var p4 = brd1.create('point', [bx + radius, by], {face:'o' , name:'p4', strokeColor: 'black', fillColor: 'red',  size:3, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var a2 = brd1.create('circle', [p3,p4], {fillOpacity:1, dash:1, strokeColor: 'black', strokeWidth: 1, fillColor: color4, layer: 2, highlightStrokeColor: 'black', highlightfillColor: color2});

//var p5 = brd1.create('point', [cx, cy], {face:'o' , name:'p5', strokeColor: 'black', fillColor: '#5B43FF',  size:3, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
//var p6 = brd1.create('point', [cx + radius, cy], {face:'o' , name:'p6', strokeColor: 'black', fillColor: 'red',  size:3, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
//var a3 = brd1.create('circle', [p5,p6], {fillOpacity:1, dash:1, strokeColor: 'black', strokeWidth: 1, fillColor: color4, layer: 2, highlightStrokeColor: 'black', highlightfillColor: color2});


// Create two intersection points
var i1 = brd1.create('intersection', [a1,a2,1], {visible:false, name:'i1', label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
//var i2 = brd1.create('intersection', [a1,a3,0], {visible:false, name:'i2', label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
//var i3 = brd1.create('intersection', [a2,a3,1], {visible:false, name:'i3', label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var i4 = brd1.create('intersection', [a1,a2,0], {visible:false, name:'i4', label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
//var i5 = brd1.create('intersection', [a1,a3,1], {visible:false, name:'i5', label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
//var i6 = brd1.create('intersection', [a2,a3,0], {visible:false, name:'i6', label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});


// Create three arcs surrounding the intersection area
// Create three arcs surrounding the intersection area BC
var c8 = brd1.create('arc', [p1, i4, i1], {visible:true, strokeColor: 'black', dash:1, strokeWidth: 1});
var c9 = brd1.create('arc', [p3, i1, i4], {visible:true, strokeColor: 'black', dash:1, strokeWidth: 1})
//
JXG.joinCurves = function(board, parents, attributes) {
    var curves = parents,
        attr = JXG.copyAttributes(attributes, board.options, 'curve'),
        c = board.create('curve', [[0], [0]], attr);

    c.updateDataArray = function() {
        var i = curves.length;

        // The paths have to be connected
        this.dataX = [];
        this.dataY = [];
        for (i = 0; i < curves.length; i++) {
            if (i < curves.length - 1) {
                this.dataX = this.dataX.concat(curves[i].dataX.slice(0,-1));
                this.dataY = this.dataY.concat(curves[i].dataY.slice(0,-1));
            } else {
                this.dataX = this.dataX.concat(curves[i].dataX);
                this.dataY = this.dataY.concat(curves[i].dataY);
            }
        }

        if (this.dataX.length<4) {
            this.bezierDegree = 1;
        } else {
            this.bezierDegree = curves[0].bezierDegree;
        }
    };
    c.prepareUpdate().update().updateVisibility().updateRenderer();
    return c;
};
///
var AB = JXG.joinCurves(brd1, [c9, c8],
   {fillOpacity:1,  fillColor:color1,  strokeColor: 'black', strokeWidth: 1, dash:1
});
var txt1 = brd1.create('text', [0, 3, ''],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}},);
var txtA = brd1.create('text', [ax, -6, 'A'],{color:colorx, visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);
txtA.on('down', function(){FunA();});
txtA.on('out', function(){white();  txt1.setText('');});
var txtB = brd1.create('text', [bx, -6, 'B'],{color:colorx,visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);
txtB.on('down', function(){FunB();});
txtB.on('out', function(){white();txt1.setText('');});
var txtAUB = brd1.create('text', [1, -6, 'A ⋃ B'],{color:colory,visible:false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);
txtAUB.on('down', function(){FunAUB();});
txtAUB.on('out', function(){white();txt1.setText('');});
var txtAOB = brd1.create('text', [0.5*(ax+bx), -6, 'A ⋂ B'],{color:colorz,visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);
txtAOB.on('down', function(){FunAOB();});
txtAOB.on('out', function(){white();txt1.setText('');});
function FunA()
  {
    white();
    a1.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
    //AC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
    AB.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
  //  ABC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
    txt1.setText('Highlighted region is set A');
  }

  function FunB()
    {
      white();
      a2.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
      //AC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
      AB.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
    //  ABC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
      txt1.setText('Highlighted region is set A');
    }

    function FunAUB()
      {
        white();
        a1.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        a2.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        //AC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
        AB.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
      //  ABC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
        txt1.setText('Highlighted region is union of A & B');
      }
      function FunAOB()
        {
          white();
          //a1.setAttribute({fillOpacity:1, dash:0,fillColor:colorz, highlightfillColor: colorz});
          //a2.setAttribute({fillOpacity:1, dash:0,fillColor:colorz, highlightfillColor: colorz});
          //AC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
          AB.setAttribute({fillOpacity:1, dash:0,fillColor:colorz, highlightfillColor: colorz});
        //  ABC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
          txt1.setText('Highlighted region is intersection of A & B');
        }

      //Individual Sets
      /*

      //
      txtB.on('over', function(){FunB();});
      //
      var txtC = brd1.create('text', [-6, -8, 'C'],{color:colorx,visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);
      txtC.on('over', function(){FunC();});
      //
      //var txtAprime = brd1.create('text', [-5, -8, 'A\''],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);
    //  txtAprime.on('down', function(){FunC();});
      //
      //var txtBprime = brd1.create('text', [-4, -8, 'B\''],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);
      //txtAprime.on('down', function(){FunC();});
      //
    //  var txtCprime = brd1.create('text', [-3, -8, 'C\''],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);
      //txtAprime.on('down', function(){FunC();});
      //Union of Sets
      /*var txtAUB = brd1.create('text', [-3.5, -8, 'A ⋃ B'],{color:colory,visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);
      txtAUB.on('over', function(){FunAUB();});
      //
      var txtBUC = brd1.create('text', [-1.5, -8, 'B ⋃ C'],{color:colory,visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);
      txtBUC.on('over', function(){FunBUC();});
      //
      var txtCUA = brd1.create('text', [0.5, -8, 'C ⋃ A'],{color:colory,visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);
      txtCUA.on('over', function(){FunAUC();});
//
//
      //Intersection of Sets
      var txtAOB = brd1.create('text', [3.5, -8, 'A ⋂ B'],{color:colorz,visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);
      txtAOB.on('over', function(){FunAOB();});
      //
      var txtBOC = brd1.create('text', [5.5, -8, 'B ⋂ C'],{color:colorz,visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);
      txtBOC.on('over', function(){FunBOC();});
      //
      var txtCOA = brd1.create('text', [ 7.5, -8, 'C ⋂ A'],{color:colorz,visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}},);
      txtCOA.on('over', function(){FunAOC();});
      //Functions
      function FunA(){
          white();
          a1.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
          AC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
          AB.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
          ABC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
          txt1.setText('This is set A');
        }
  //
      function FunB(){
      white();
      a2.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
      BC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
      AB.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
      ABC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
      txt1.setText('This is set B');
    }
//
      function FunC(){
        white();
        a3.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
        AC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
        BC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
        ABC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
        txt1.setText('This is set C');
      }

      function FunAUB(){
        white();
        a1.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        a2.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        AB.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        AC.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        BC.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        ABC.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        txt1.setText('This is union of sets A and B');
        }
      function FunBUC(){
        white();
        a2.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        a3.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        AB.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        AC.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        BC.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        ABC.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        txt1.setText('This is union of sets B and C');
      }
      function FunAUC(){
        white();
        a1.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        a3.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        AB.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        AC.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        BC.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        ABC.setAttribute({fillOpacity:1, dash:0,fillColor:colory, highlightfillColor: colory});
        txt1.setText('This is union of sets A and C');
      }
//
function FunAOB(){
  white();
  AB.setAttribute({fillOpacity:1, dash:0,fillColor:colorz, highlightfillColor: colorz});
  ABC.setAttribute({fillOpacity:1, dash:0,fillColor:colorz, highlightfillColor: colorz});
  txt1.setText('This is intersection of sets A and B');
  }

  function FunAOC(){
    white();
    AC.setAttribute({fillOpacity:1, dash:0,fillColor:colorz, highlightfillColor: colorz});
    ABC.setAttribute({fillOpacity:1, dash:0,fillColor:colorz, highlightfillColor: colorz});
    txt1.setText('This is intersection of sets A and C');
    }

    function FunBOC(){
      white();
      BC.setAttribute({fillOpacity:1, dash:0,fillColor:colorz, highlightfillColor: colorz});
      ABC.setAttribute({fillOpacity:1, dash:0,fillColor:colorz, highlightfillColor: colorz});
      txt1.setText('This is intersection of sets B and C');
      }
      */
      //slid.on('drag',function(){ idk();});
/*
      function idk(){

          white();

          if(slid.Value() == 20){
              a1.setAttribute({fillOpacity:1, dash:0, fillColor:color2, highlightfillColor: color2});
              a2.setAttribute({fillOpacity:1, dash:0, fillColor:color2, highlightfillColor: color2});
              a3.setAttribute({fillOpacity:1, dash:0, fillColor:color2, highlightfillColor: color2});
              AB.setAttribute({fillOpacity:1, dash:0, fillColor:color2, highlightfillColor: color2});
              AC.setAttribute({fillOpacity:1, dash:0, fillColor:color2, highlightfillColor: color2});
              BC.setAttribute({fillOpacity:1, dash:0, fillColor:color2, highlightfillColor: color2});
              ABC.setAttribute({fillOpacity:1, dash:0, fillColor:color2, highlightfillColor: color2});
              universe.setAttribute({fillOpacity:1, dash:0, fillColor:color2, highlightfillColor: color2});
              txt1.setText('U');
          }
          else if(slid.Value() == 2){
              a1.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
              AC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
              AB.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
             ABC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
              txt1.setText('A');
          }
          else if(slid.Value() == 3){
              a2.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
              BC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
              AB.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
              ABC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
              txt1.setText('B');
          }
          else if(slid.Value() == 4){
              a3.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
              AC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
              BC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
              ABC.setAttribute({fillOpacity:1, dash:0,fillColor:color2, highlightfillColor: color2});
              txt1.setText('C');
          }
          else if(slid.Value() == 5){
              a2.setAttribute({fillOpacity:1,fillColor:color2, highlightfillColor: color2});
              a3.setAttribute({fillOpacity:1,fillColor:color2, highlightfillColor: color2});
              BC.setAttribute({fillOpacity:1,fillColor:color2, highlightfillColor: color2});
              universe.setAttribute({fillOpacity:1,fillColor:color2, highlightfillColor: color2});
              txt1.setText("A'");
          }
          else if(slid.Value() == 6){
              a1.setAttribute({fillOpacity:1,fillColor:color2, highlightfillColor: color2});
              a3.setAttribute({fillOpacity:1,fillColor:color2, highlightfillColor: color2});
              AC.setAttribute({fillOpacity:1,fillColor:color2, highlightfillColor: color2});
              universe.setAttribute({fillOpacity:1,fillColor:color2, highlightfillColor: color2});
              txt1.setText("B'");
          }
          else if(slid.Value() == 7){
              a1.setAttribute({fillColor:color2, highlightfillColor: color2});
              a2.setAttribute({fillColor:color2, highlightfillColor: color2});
              AB.setAttribute({fillColor:color2, highlightfillColor: color2});
              universe.setAttribute({fillColor:color2, highlightfillColor: color2});
              txt1.setText("C'");
          }
          else if(slid.Value() == 8){
              a1.setAttribute({fillColor:color2, highlightfillColor: color2});
              a2.setAttribute({fillColor:color2, highlightfillColor: color2});
              AB.setAttribute({fillColor:color2, highlightfillColor: color2});
              AC.setAttribute({fillColor:color2, highlightfillColor: color2});
              BC.setAttribute({fillColor:color2, highlightfillColor: color2});
              ABC.setAttribute({fillColor:color2, highlightfillColor: color2});
              txt1.setText('A ⋃ B');
          }
          else if(slid.Value() == 9){
              a1.setAttribute({fillColor:color2, highlightfillColor: color2});
              a3.setAttribute({fillColor:color2, highlightfillColor: color2});
              AB.setAttribute({fillColor:color2, highlightfillColor: color2});
              AC.setAttribute({fillColor:color2, highlightfillColor: color2});
              BC.setAttribute({fillColor:color2, highlightfillColor: color2});
              ABC.setAttribute({fillColor:color2, highlightfillColor: color2});
              txt1.setText('A ⋃ C');
          }
          else if(slid.Value() == 10){
              a2.setAttribute({fillColor:color2, highlightfillColor: color2});
              a3.setAttribute({fillColor:color2, highlightfillColor: color2});
              AB.setAttribute({fillColor:color2, highlightfillColor: color2});
              AC.setAttribute({fillColor:color2, highlightfillColor: color2});
              BC.setAttribute({fillColor:color2, highlightfillColor: color2});
              ABC.setAttribute({fillColor:color2, highlightfillColor: color2});
              txt1.setText('B ⋃ C');
          }

          else if(slid.Value() == 11){
              AB.setAttribute({fillColor:color2, highlightfillColor: color2});
              ABC.setAttribute({fillColor:color2, highlightfillColor: color2});
              txt1.setText('A ⋂ B');
          }
          else if(slid.Value() == 12){
              AC.setAttribute({fillColor:color2, highlightfillColor: color2});
              ABC.setAttribute({fillColor:color2, highlightfillColor: color2});
              txt1.setText('A ⋂ C');
          }
          else if(slid.Value() == 13){
              BC.setAttribute({fillColor:color2, highlightfillColor: color2});
              ABC.setAttribute({fillColor:color2, highlightfillColor: color2});
              txt1.setText('B ⋂ C');
          }

          else if(slid.Value() == 14){
              a1.setAttribute({fillColor:color2, highlightfillColor: color2});
              AC.setAttribute({fillColor:color2, highlightfillColor: color2});
              txt1.setText('A - B');
          }
          else if(slid.Value() == 15){
              a2.setAttribute({fillColor:color2, highlightfillColor: color2});
              BC.setAttribute({fillColor:color2, highlightfillColor: color2});
              txt1.setText('B - A');
          }

          else if(slid.Value() == 16){
              a2.setAttribute({fillColor:color2, highlightfillColor: color2});
              AB.setAttribute({fillColor:color2, highlightfillColor: color2});
              txt1.setText('B - C');
          }

          else if(slid.Value() == 17){
              a3.setAttribute({fillColor:color2, highlightfillColor: color2});
              AC.setAttribute({fillColor:color2, highlightfillColor: color2});
              txt1.setText('C - B');
          }
          else if(slid.Value() == 18){
              a3.setAttribute({fillColor:color2, highlightfillColor: color2});
              BC.setAttribute({fillColor:color2, highlightfillColor: color2});
              txt1.setText('C - A');
          }
          else if(slid.Value() == 19){
              a1.setAttribute({fillColor:color2, highlightfillColor: color2});
              AB.setAttribute({fillColor:color2, highlightfillColor: color2});
              txt1.setText('A - C');
          }
          else if(slid.Value() == 1){
              txt1.setText('∅');
          }
          else{
              return;
          }
      }
*/
      function white(){
          AB.setAttribute({fillOpacity:1, dash:1,fillColor:color4, highlightfillColor: color1});
          //AC.setAttribute({fillOpacity:1, dash:1,fillColor:color4, highlightfillColor: color1});
          //BC.setAttribute({fillOpacity:1, dash:1,fillColor:color4, highlightfillColor: color1});
          //ABC.setAttribute({fillOpacity:1,dash:1,fillColor:color4, highlightfillColor: color1});
          a1.setAttribute({fillOpacity:1, dash:1,fillColor:color4, highlightfillColor: color1});
          a2.setAttribute({fillOpacity:1, dash:1,fillColor:color4, highlightfillColor: color1});
          //a3.setAttribute({fillOpacity:1, dash:1,fillColor:color4, highlightfillColor: color1});
          //universe.setAttribute({fillOpacity:1, dash:1,fillColor:color4, highlightfillColor: color1});
      }


      brd1.unsuspendUpdate();
    },
}
export default Boxes;
